:root {
    /* color respuesta de chat */
    --color-pale-yellow: #FDF4D7; /* rgb(86,7,12) */
    --color-text: #333;
    --color-card: rgba(255, 255, 255, 0.9);
    --color-blue: #007bff;
}

.full-background-img {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 100vh;
}

.row{
    /* borde derecho chat (tiene que ser > 0)  */
    margin-right: 0px;
    /* borde izquierdo chat (tiene que ser > 0)  */
    margin-left: 0px;
    /* borde inferior chat (tiene que ser >= 0)  */
    margin-bottom: 20px;
}

/* Buttons "siguiente", personalizar a gusto! */
.custom-button {}

/* imagen inicial*/
.img-inicial {
    width: 100%;
    height: auto;
}

/* clase para las preguntas de caritas */
.custom-button.answer-button.image-button {
  background-color: transparent;
  border: none;
  /*distancia entre caritas */
  padding: 2px 2px!important;
}

/* clase para las preguntas de caritas */
.custom-button.answer-button.image-button img {
  /* tamaño de las caritas */
  width: 40px;
  max-width: 65px;
  max-height: 65px;
  height: auto;
}
/* End Buttons */

/* Circle Timeout */
.circle-timeout {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1;
    width: 30px;
    height: 30px;
    border-radius: 60px;
    cursor: pointer;
}
.circle-timeout #countdown {
    position: relative;
    margin: auto;
    width: 20px;
    text-align: center;
}
.circle-timeout #countdown-number {
    color: var(--color-text);
    display: inline-block;
    line-height: 30px;
    font-size: 12px;
}
.circle-timeout #countdown-number > svg {
    font-size: 20px;
    margin-top: -2px;
}
.circle-timeout #countdown > svg {
    position: absolute;
    top: -5px;
    right: -10px;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
}
.circle-timeout svg circle {
    stroke-dasharray: 60px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: var(--color-text);
    fill: none;
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0px;
    }
    to {
        stroke-dashoffset: 60px;
    }
}
/* END Circle Timeout */

/* Custom Input */
.custom-input {
    position: relative;
}
.custom-input textarea.emojis {
    padding-left: 40px;
}
.custom-input button {
    position: absolute;
    left: 0;
    top: -10px;
    z-index: 1;
}
.emoji-picker-react .content-wrapper .emoji-scroll-wrapper ul li button img {
    width: auto;
}
/* End Custom Input */

/* Radios */
.answer-radio.image-radio {
    display: initial;
    padding: 0;
}
.answer-radio.image-radio [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
  .answer-radio.image-radio img {
    cursor: pointer;
    opacity: 0.5;
}
.answer-radio.image-radio img.checked {
    opacity: 1;
}
/* End Radios */

/* Start Carousel */
.carousel .alice-carousel__stage-item {
    padding: 0 5px;
}
.carousel .carousel-controller {
    position: absolute;
    top: 45%;
    width: 100%;
    justify-content: space-between;
    display: flex;
}
.carousel .carousel-controller button {
    background-color: transparent;
    border: none;
    color: var(--color-blue);
    font-size: 30px;
}
.carousel .alice-carousel__stage-item .card {
    min-height: 330px;
}
.carousel .alice-carousel__stage-item .card .card-body {
    padding: 10px 10px 40px 10px;
}
.carousel .alice-carousel__stage-item .card .card-body button {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    border-radius: 0;
}
/* End Carousel */

/* Start DnD */
.question .answer-dnd .card .list-group ul {
    padding: 0;
    margin: 0;
}
.question .answer-dnd .card .list-group ul div {
    background-color: rgba(0, 123, 255, 0.5);
}
/* End DnD */

.question-rating {  
    width: 500; 
    display: flex;
    align-items: center 
}

.survey-card {
    color: var(--color-text);
    border-radius: 50px;
    padding: 40px 20px;
    margin-top: 2%;
}
.survey-card.left {
    background-color: var(--color-card);
    margin-right: 10px;
}
.survey-card.right {
    background-color: transparent;
    border: none;
    border-radius: inherit;
    margin-left: 10px;
    padding-left: 20px;
    background-repeat: no-repeat;
    height: 700px;
}
/* CHAT */
.chat {
    background-image: url('../../assets/img/backgroundPhone.jpg');
    width: 361px;
    height: 535px;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}
.chat .chat-header {
    /* alto sticky */
    padding-top: 15px;
    padding-bottom: 5px;
    /* fondo sticky */
    background-color: #FFF;
    /*letra sticky */
    color: black;
    filter: alpha(opacity=80);
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.chat .chat-header.sticky {
    position: fixed;
}
.chat .chat-header .header-logo img {
    /* tamaño logo sticky */
    width: 40px;
    float: left;
    padding-top: 0px;
}
.chat .chat-header > .row {
    /* ancho sticky */
    width: 100%;
    margin: 0;
}
.chat .chat-header .header-info {
    /* alineacion y padding texto de sticky */
    text-align: left;
    padding-left: 3px;
}
.chat .chat-header .header-info .action {
    /* tamaño fuente "online" "escribiendo" de sticky */
    font-size: 10px;
}
.chat .chat-header .header-progress {
    /* estilo de numero pasos de sticky 1/5 -> 2/5 -> etc */
    text-align: right;
    padding-right: 20px;
    font-size: 14px;
    padding-left: 0;
}
.chat .question-content {
    /* estilo caja de chat mobile */
    width: 100%!important;
    overflow-y: auto;
    overflow-x: initial;
    float: left;
    padding: 10px 20px;
}
/* END CHAT */


.legals-buttons {
    justify-content: space-between;
    display: flex;
}
.alignRight {
    /* estilo de numero pasos de sticky 1/5 -> 2/5 -> etc */
    text-align: right;
}

.survey-card .title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}
.survey-card .title p {
    margin: 0;
}

.height-100{
    height: 100vh!important;
}


/* estilo caja de respuesta */
.question {
    background-color: var(--color-pale-yellow);
    border-radius: 7px;
    padding: 10px;
    border: 1px solid #F3DFA3;
    margin-bottom: 20px;
    display: inline-block;
    margin-left: 15px;
    position: relative;
    width: -webkit-fill-available;
}

/* estilo caja respuestas mobile */
.question.user {
    border: 1px solid #fff;
    background: #fff;
    margin-right: 15px;
    margin-left: 0;
    float: right;
}

/* triangulo lateral de la burbuja de pregunta */
.question:not(.user):not(.no-arrow):after {
    content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-right-color: var(--color-pale-yellow);
	border-left: 0;
	border-bottom: 0;
	margin-top: -10px;
	margin-left: -20px;
}

/* triangulo lateral de la burbuja de respuesta */
.question.user:not(.no-arrow):after {
    content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left-color: #fff;
	border-right: 0;
	border-bottom: 0;
	margin-top: -10px;
	margin-right: -20px;
}

/* tamaño general de la fuente */
.question .label {
    font-size: 20px;
}

/* margen de la pregunta */
.question .answer {
    text-align: left;
    margin: 10px 0;
    display: flow-root;
}

/* opacidad de la pregunta */
.last-questions .answer {
    opacity: 0.5;
}
.last-questions input,
.last-questions div,
.last-questions button {
    pointer-events: none !important;
}
.question .answer button img {
    width: 40px;
}
.question .answer button.go-back {
    position: absolute;
    top: -15px;
    left: -15px;
    box-shadow: none !important;
    border-color: inherit !important;
    border: none !important;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 60px;
}
.question .answer button.go-back,
.question .answer .circle-timeout {
    background-color: var(--color-pale-yellow);
}
.kiosco .question .answer button.go-back {
    left: 0;
}
.kiosco .question .answer .circle-timeout {
    right: 0;
}
.Responsive .question .answer button.go-back,
.Responsive .question .answer .circle-timeout,
.Kiosco .question .answer button.go-back,
.Kiosco .question .answer .circle-timeout {
    background-color: #fff;
}

.answer button.go-back img {
    width: 20px;
    margin-top: -4px;
}
.answer button.go-back:active,
.answer button.go-back:hover,
.custom-button.answer-button.image-button {
    box-shadow: none !important;
    border: none;
}

.question .answer .answer-text button,
.question .answer .answer-radio button,
.question .answer .answer-checkbox button,
.question .map-container button,
.question .answer .file-answer button,
.question .required-field button,
.question .answer .answer-select > button,
.question .answer .answer-btn,
.question .answer-dnd > button {
    margin-top: 10px;
    float: right;
}
.question .answer .answer-select .dropdown > button {
    width: 100%;
}
.question .answer .file-answer {
    text-align: center;
}
.question .answer .answer-text canvas,
.question .answer .answer-text audio {
    max-width: 100%;
}
.question .answer .answer-text .audio-recorder {
    margin-top: 10px;
    display: inline-block;
}
.question .answer .answer-text .audio-recorder .audio-control {
    display: inline-block;
}
.question .answer .answer-text.datetime input.form-control {
    width: 49%;
    display: inline-block;
}
.question .answer .answer-text.datetime input.form-control:first-child {
    margin-right: 2%;
}
.question .answer .answer-radio input,
.question .answer .answer-checkbox input,
.question .answer .answer-select input {
    margin-top: 4px!important;
}
.question .answer .answer-select .dropdown .dropdown-toggle {
    border: 1px solid;
    border-radius: 5px;
}
.question .answer .answer-radio .image-radio img {
    width: 100%;
    height: auto;
}
.question .answer .answer-radio .more-info {
    float: none;
    display: block;
    margin-bottom: 10px;
    margin-top: 0;
    padding: 0;
    font-weight: bold;
    color: var(--color-text);
}
.question .answer .answer-checkbox {
    text-align: left;
}

.question .map {
    height: 300px;
    width: 100%;
    position: relative;
}
.question .map input {
    position: absolute;
    z-index: 999;
    left: 20px;
    top: 10px;
    width: 50%;
    border-radius: 5px;
    border: 1px solid #cecece;
    padding: 5px;
}
.question .answer .file-answer .select-file > div {
    text-align: center;
    display: inline-block;
}
.question .answer .file-answer .select-file .o {
    margin: 5px 0;
    display: block;
    font-weight: bold;
}
.question .answer .file-answer .select-file .camera .pick {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #cecece;
    padding: 5px;
    border-radius: 5px;
}
.question .answer .file-answer .select-file .camera svg {
    vertical-align: text-bottom;
}
.question .answer .file-answer .select-file .camera .react-html5-camera-photo video {
    max-width: 100%;
    max-height: 300px;
}
.question .answer .file-answer .preview {
    display: block;
}
.question .answer .file-answer .preview video {
    height: 300px;
    width: auto;
    max-width: 100%;
    margin-top: 10px;
}
.question .answer .file-answer .preview img {
    width: 100%;
    height: auto;
    margin-top: 10px;
}

/* Responsive / Kiosco */
.responsive {
    /* margen superior caja general */
    margin-top: 2%;
    /* background caja general */
    background-color: rgba(255,255,255,0.74);
    /* radio caja general */
    border-radius: 12px;
}

.responsive .question-content .question,
.kiosco .question-content .question {
    background-color: inherit;
    border: none;
    /* margen general */
    margin: 0;
    /* padding general */
    padding: 20px;
    display: inline-block;
}
.responsive .question-content .responsive-content,
.kiosco .question-content .responsive-content {
    overflow-y: auto;
    overflow-x: hidden;
}
.responsive .question-content .responsive-content {
    padding: 20px;
}
.kiosco .question-content .responsive-content {
    padding: 10px 0;
}
.responsive .question-content .responsive-content .question,
.kiosco .question-content .responsive-content .question {
    /* borde y background de las preguntas */
    border: 1px solid #fff;
    background-color: #fff;
    /* posicion de las preguntas */
    margin-left: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 98%;
}
.kiosco .question-content .responsive-content .question {
    width: 100%;
}
.kiosco .question-content .responsive-content .question {
    margin-left: 0;
}
.responsive .question-content .responsive-content .question:not(.no-arrow):after,
.kiosco .question-content .responsive-content .question:not(.no-arrow):after {
    /* borde color tringulito burbuja pregunta */
    border-right-color: #fff;
}
.kiosco .question-content .responsive-content .question:not(.no-arrow):after {
    display: none;
}

/* estilo de respuesta */
.responsive .question-content .responsive-content .question.user,
.kiosco .question-content .responsive-content .question.user {
    /* color de respuesta */
    background-color: var(--color-pale-yellow);
    border: 1px solid #F3DFA3;
    /* posicion de respuesta */
    margin-right: 15px;
    margin-left: 0;
}
.kiosco .question-content .responsive-content .question.user {
    margin-right: 0;
}
.responsive .question-content .responsive-content .question.user:not(.no-arrow):after,
.kiosco .question-content .responsive-content .question.user:not(.no-arrow):after {
    /* color triangulo de respuesta */
    border-left-color: var(--color-pale-yellow);
}
.kiosco .question-content .responsive-content .question.user:not(.no-arrow):after {
    display: none;
}
.kiosco .question-content .responsive-content .last-questions,
.kiosco .question-content .responsive-content .last-questions {
    display: none;
}
.kiosco .custom-button.answer-button.image-button img {
    width: 80px;
}
/* End Responsive/Kiosco */
/* Kiosco */
.kiosco > img {
    width: 100%;
    height: auto;
}
.kiosco .question-content {
    margin-top: 10px;
}
.kiosco .question-content .question {
    background-color: rgba(255,255,255,0.74) !important;
    border-radius: 12px;
}
.kiosco .question-content .responsive-content .question:not(.no-arrow):after {
    border-right-color: rgba(255,255,255,0.74);
}
.kiosco .question-content .question .answer .btn-toolbar {
    justify-content: space-between;
}
.kiosco .question-content .question .answer .btn-toolbar .image-button img {
    width: 100px;
}
/* End Kiosco */
/* Start end-survey */
.end-survey .card {
    padding: 10px;
    border-radius: 12px;
}
.end-survey .card .card-body {
    padding: 0;
    text-align: center;
}
.end-survey .card .card-title {
    font-size: 16px;
    text-align: center;
    margin: 10px 0 0 0;
}
.end-survey .card .card-body p {
    margin: 0;
}
.end-survey .card .card-body .share-buttons {
    justify-content: space-around;
    display: flex;
    margin: 10px 0;
}
.end-survey .card .card-body .share-buttons img {
    width: 75px;
    height: auto;
    cursor: pointer;
}
.end-survey .card .card-body .powered {
    text-align: center;
}
.end-survey .card .card-body .powered .muted {
    font-size: 11px;
    margin: 0;
}
/* End end-survey */

#help-popover:not(.more) {
    top: -60px;
}
.Kiosco #help-popover {
    top: -40px !important;
}

@media (min-width: 995px) {
    .survey-container .renders .company-info.last {
        display: none;
    }
}

@media (min-width: 995px) and (max-width: 1100px) {
    .survey-container .renders .company-info,
    .survey-container .renders .surveys {
        padding: 0;
    }
}

@media (max-width: 994px) {
    .survey-container  .renders {
        justify-content: center;
    }
    .survey-container .renders .company-info.last {
        display: contents;
    }
    .survey-container .renders .company-info:not(.last) {
        display: none !important;
    }
    .survey-container .renders .company-info,
    .survey-container .renders .surveys {
        display: contents;
    }
}

@media (max-width: 1100px) {
    .survey-card.right,
    .survey-card.lefft {
        margin-left: 0;
        margin-right: 0;
    }
    .survey-card.right {
        padding-top: 45px;
    }
    .survey-card.right .chat {
        padding-top: 0;
    }
}

@media (max-width: 800px) {
    .chat {
        /*display: inline-block;*/
        height: auto;
        background-image: none;
        width: 100%;
        margin-top: 0;
    }
    .chat .question-content {
        width: 100%;
    }
    .end-survey .card .card-body .share-buttons img {
        width: 70px;
        height: auto;
        cursor: pointer;
    }
    .survey-card {
        background-color: rgba(255,255,255,0.74);
        border-radius: 7px;
        padding: 10px;
    }
    .responsive .question-content .responsive-content,
    .kiosco .question-content .responsive-content {
        padding: 10px 20px;
    }
}

@media (max-width: 450px) {
    .chat .row {
        max-width: 100%;
    }
    .chat .question-content {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .custom-button.answer-button.image-button img {
        width: 30px;
    }
    .kiosco .question-content .question .answer .btn-toolbar .image-button img {
        width: 40px;
    }
    .question .label {
        font-size: 16px;
    }
    .isIOS #last-content {
        height: 50px;
        width: 100%;
        display: block;
    }
    .question .answer .answer-text.datetime input.form-control {
        width: 100%;
    }
    .question .answer .answer-text.datetime input.form-control:first-child {
        margin-right: 0%;
        margin-bottom: 10px;
    }
    
}